/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {HrefLangManager, SideBySide, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/about-us/'], ['en', 'https://www.hear.com/about-us/'], ['en-US', 'https://www.hear.com/about-us/'], ['en-IN', 'https://www.hear.com/in/about-us/'], ['en-ZA', 'https://www.hear.com/za/about/'], ['en-MY', 'https://www.hear.com/my/about-us/'], ['ko-KR', 'https://www.hear.com/kr/about-hearcom'], ['en-CA', 'https://ca.hear.com/about-us/']]
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "your-1-source-for-better-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#your-1-source-for-better-hearing",
    "aria-label": "your 1 source for better hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Your #1 source for better hearing"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/Paul-Crusius-and-Marco-Vietor.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "We are the global leader in the hearing aid industry, providing the most comprehensive care for all your hearing needs needs in more than 10 countries. We offer free expert advice over the phone, free testing of the latest devices as well as the best prices for hearing aids in Canada. Our staff is made up of hearing aid experts and experienced audiologists who would be happy to assist you in finding the perfect hearing loss solution. With our 200+ Partner Providers with convenient locations across Canada, you can rest assured that the perfect hearing aid is just around the corner from your home!")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Not only are we familiar with the obstacles and challenges of hearing loss, but we are also able to provide you with hearing aids from the best brands. We work with the leading manufacturers to find a digital hearing aid that is comfortable, effective, and affordable. We are just a phone call away, so don’t hesitate to contact us."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "our-team-hearing-experts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-team-hearing-experts",
    "aria-label": "our team hearing experts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our team: hearing experts"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/how-it-works-hearcom-team.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our ears are as unique as our fingerprint, which means that hearing aids are not one-size-fits-all. At hear.com, you have free access to experienced hearing aid experts who can guide through the process of finding the right device. Understanding your situation and empathizing with you are the two most important characteristics of all our phone consultations. We will do everything we can to find the best hearing aids for you. Our main objective, as hearing experts, is to help you achieve better hearing – and a better quality of life.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "our-goal-simply-good-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-goal-simply-good-hearing",
    "aria-label": "our goal simply good hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our goal: simply good hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com is the only hearing aid provider that guarantees better hearing in just 30 days, or your money back! This is only possible with our exclusive Hearing Success Program. It is absolutely free to join, and it includes a 30-day hearing training guide that will help you get the most out of your new hearing aids – ensuring they stay in your ears and not on your night stand. The program currently has a satisfaction rate over 92%, and the number continues to increase with every new hearing success story. But don’t just take our word for it – head over to our customer voices page to read what people are saying about their renewed quality of life, thanks to hear.com."), "\n", React.createElement(ButtonCta, {
    copy: "GET IN TOUCH NOW!",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
